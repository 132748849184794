import React, { useState } from "react"
import { graphql } from "gatsby"
import Seo from "../components/seo"
import { checkShowForm, getFirstText, getYouTubeEmbedUrl, initializeHubspotForm } from "../utils/gatsby-helpers"
import rehypeRaw from "rehype-raw"
import ReactMarkdown from "react-markdown"
import scrollTo from "gatsby-plugin-smoothscroll"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import CardPost from "../components/v2023/UI/Card/card-post"
import NewLayout from "../components/new_layout"
import { Modal } from "antd"
import YoutubeVideo from "../components/v2024/video/youtube-video"

const CaseStudyPage = ({ data, pageContext, location, lang = "en" }) => {

    const titleHistory = {
        "en": "Read more stories",
        "es": "Lee otras historias"
    }

    const titleTag = {
        "en": "Case Study",
        "es": "Caso de éxito"
    }

    const caseStudy = data.caseStudy
    const form = caseStudy.hubspot_form
    const others = data.others.edges

    const homePath = pageContext.langKey === "en" ? "/" : `/${pageContext.langKey}`
    const pathCaseStudy = pageContext.langKey === "en" ? "/case-studies" : "/es/casos-exito"

    const [isModalVisible, setIsModalVisible] = useState(checkShowForm(location))
    const [videoModal, setVideoModal] = useState(false)
    const [videoLoading, setVideoLoading] = useState(true)

    const showModal = () => {
        setIsModalVisible(true)
    }

    const handleCancel = () => {
        setIsModalVisible(false)
    }

    const openVideoModal = () => {
        setVideoModal(!videoModal)
    }

    const spinnerVideo = () => {
        setVideoLoading(!videoLoading)
    }

    const elements = (pClass) => {
        return {
            p: ({ node, ...props }) => <h4 className={pClass} {...props} />
        }
    }

    const scrollAndShowModal = () => {
        scrollTo("#case-study-header")
        showModal()
    }

    initializeHubspotForm(form.portal_id, form.form_id, "#form-case-study", form.region, { casestudy_name: caseStudy.hubspot_casestudy_name })

    return (
        <NewLayout pageContext={pageContext}>
            <Seo
                lang={pageContext.langKey}
                title={caseStudy.seo.title}
                description={caseStudy.seo.meta_description}
                translates={pageContext.translates}
                image={caseStudy.seo.image_2?.localFile?.publicURL}
            />

            <main className="main">
                <div className="main__section main__section--50 main__section--50--lg white-section green-sm" id="case-study-header">
                    <div className="container">
                        <div className="container__50">
                            <div className="tag tag--highlight">{titleTag[pageContext.langKey]}</div>
                            <h1 className="merriweather">{caseStudy.title}</h1>
                            <button className="button button--lg" onClick={showModal}>{caseStudy.download_button}</button>
                        </div>
                        <div className="container__50"  data-aos="fade-left">
                            <GatsbyImage alt={caseStudy.logo_new.alternativeText} image={getImage(caseStudy.logo_new.localFile)} />
                        </div>
                    </div>
                </div>
                {caseStudy.opinion &&
                    <div className="main__section main__section--100 color-section purple" >
                        <div className="container">
                            <div className="tile tile--white--nb">
                                <i className="icon-blockquote i-xl"></i>
                                <div className="tile__body">
                                    <div className="fs-24">
                                        {caseStudy.opinion.message}
                                    </div>
                                </div>
                                <div className="avatar">
                                    {caseStudy.opinion.photo &&
                                        <div className="avatar__photo">
                                            <GatsbyImage alt={caseStudy.opinion.photo.alternativeText} image={getImage(caseStudy.opinion.photo.localFile)} />
                                        </div>
                                    }
                                    <div className="avatar__job">
                                        <b>{caseStudy.opinion.name}</b>
                                        <span>{caseStudy.opinion.job_position}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                }
                {caseStudy.video &&
                    <div className="main__section main__section--100">
                        <div className="container">
                            <YoutubeVideo preview={{localFile: caseStudy.video.video_preview.localFile, alternativeText: caseStudy.video.video_preview.alternativeText}} video={caseStudy.video.video_url}/>
                        </div>
                    </div>
                }
                {caseStudy.metrics && caseStudy.metrics.length > 0 &&
                    <div className="main__section main__section--100" data-aos="fade-up">
                        <div className="container">
                            <div className="grid-sm-3">
                                {caseStudy.metrics.map((metric, index) => {
                                    const animation = {"data-aos":"zoom-in", "data-aos-delay": 200 * index}
                                    return <div className="stat" key={index} {...animation}>
                                        <div className="title-xl">{metric.name}</div>
                                        <ReactMarkdown children={metric.description} rehypePlugins={[rehypeRaw]} />
                                    </div>  
                                })}
                            </div>
                        </div>
                    </div>
                }
                <div data-aos="fade-up">
                    {caseStudy.content.map((item, index) => (
                        <div className="main__section main__section--100" key={index} >
                            <div className="container container--slim">
                                <div className="title-xl">{item.title}</div>
                                <div className="container__content">
                                    <ReactMarkdown children={item.description} rehypePlugins={[rehypeRaw]} />
                                </div>
                                {item.icon &&
                                    <div className="container__text__image">
                                        <GatsbyImage alt={item.icon.alternativeText} image={getImage(item.icon.localFile)} />
                                    </div>
                                }
                            </div>
                        </div>
                    ))}
                </div>

                <div className="main__section main__section--100">
                    <div className="container">
                        <div className="tile">
                            <ReactMarkdown children={caseStudy.cta.description} rehypePlugins={[rehypeRaw]} components={elements()} />
                            <div className="tile__body">
                                <button className="button" onClick={scrollAndShowModal}>{caseStudy.cta.button}<i className="icon-arrow-right"></i></button>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="main__section main__section--100">
                    <div className="container">
                        <h2>{titleHistory[pageContext.langKey]}</h2>
                        <div className="container__list-horizontal">
                            <div className="grid-lg-3-h">
                                {others.map((item, index) => {
                                    const animation = {"data-aos":"zoom-in", "data-aos-delay": 200 * index}
                                    return <CardPost
                                        key={index}
                                        lang={lang}
                                        name={item.node.title}
                                        image={item.node.logo_new}
                                        url={`${pathCaseStudy}/${item.node.url}`}
                                        description={getFirstText(item.node.seo.meta_description)}
                                        animation={animation}
                                    />
                                })}
                            </div>
                        </div>
                    </div>
                </div>
            </main>

            <Modal width={"100%"} visible={isModalVisible} onCancel={handleCancel} footer={null}>
                <div id="form-case-study" className="form-hb" />
            </Modal>
            {videoModal &&
                <div className="tc-modal">
                    <div className="tc-modal__content">
                        <div className="tc-modal__content__header">
                            <button className="button-icon button-icon--sm" onClick={openVideoModal}>
                                <i className="icon-close"></i>
                            </button>
                        </div>
                        <div className="tc-modal__content__body">
                            <iframe
                                onLoad={spinnerVideo}
                                loading="lazy"
                                src={getYouTubeEmbedUrl(caseStudy.video.video_url)}
                                title="YouTube video player"
                                frameBorder="0"
                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                allowFullScreen={true}
                            />
                        </div>
                    </div>
                </div>
            }
        </NewLayout>
    )
}

export default CaseStudyPage


export const eventQuery = graphql`
    query ($id: String!, $langKey: String!) {
        caseStudy: strapiCaseStudy (locale: {eq: $langKey}, id: {eq: $id}) {
            seo {
                title
                meta_description
                image_2 {
                    localFile {
                        publicURL

                    }
                }
            }
            id
            title
            download_button
            logo_new {
                name
                alternativeText
                localFile {
                    childImageSharp {
                        gatsbyImageData(
                            width: 608
                            placeholder: BLURRED
                            formats: [WEBP]
                        )
                    }
                }
            }
            metrics {
                name
                description
            }
            video {
                video_url
                video_preview {
                    name
                    alternativeText
                    localFile {
                        childImageSharp {
                            gatsbyImageData(
                                width: 1024
                                placeholder: BLURRED
                                formats: [WEBP]
                            )
                        }
                    }
                }
            }
            opinion {
                name
                job_position
                message
                photo {
                    name
                    alternativeText
                    localFile {
                        childImageSharp {
                            gatsbyImageData (
                                height: 50
                                width: 50
                                placeholder: BLURRED
                                formats: [WEBP]
                            )
                        }
                    }
                }
            }
            content {
                order
                title
                description
                icon {
                    name
                    alternativeText
                    localFile {
                        childImageSharp {
                            gatsbyImageData(
                                width: 1024
                                placeholder: BLURRED
                                formats: [WEBP]
                            )
                        }
                    }
                }
            }
            url
            cta {
                description
                button
            }
            hubspot_casestudy_name
            hubspot_form {
                id
                region
                portal_id
                form_id
            }
        }
        others: allStrapiCaseStudy (
            filter: { locale: { eq: $langKey }, id: { ne: $id } }
            sort: { fields: [published_at], order: [DESC] }
            limit: 3
        ) {
            edges {
                node {
                    seo {
                        title
                        meta_description
                    }
                    url
                    title
                    content {
                        description
                    }
                    logo_new {
                        name
                        alternativeText
                        localFile {
                            childImageSharp {
                                gatsbyImageData(
                                    width: 608
                                    placeholder: BLURRED
                                    formats: [WEBP]
                                )
                            }
                        }
                    }
                }
            }
        }
    }
`

// metrics {
//     name
//     description
// }
